import request from '../utils/request';
const baseUrl = '/api'
const baseHost = '/api'

export const baseHostUrl = query => {
    return window.location.origin
};

export const getheadfootdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getheadfootdata`,
        method: 'get',
        params: query
    });
};

export const index = query => {
    return request({
        url: `${baseUrl}/Homepage/index`,
        method: 'get',
        params: query
    });
};

export const getorgandata = query => {
    return request({
        url: `${baseUrl}/Homepage/getorgandata`,
        method: 'get',
        params: query
    });
};

export const getdynamicsdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getdynamicsdata`,
        method: 'get',
        params: query
    });
};

export const getdydetailsdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getdydetailsdata`,
        method: 'get',
        params: query
    });
};
export const getjoinusdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getjoinusdata`,
        method: 'get',
        params: query
    });
};
export const getproductsdata = query => {
    return request({
        url: `${baseUrl}/Homepage/getproductsdata`,
        method: 'get',
        params: query
    });
};
export const getproductsdataPost = data => {
    return request({
        url: `${baseUrl}/Homepage/getproductsdata`,
        method: 'post',
        data: data
    });
};