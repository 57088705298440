<template>
  <div id="app">
    <Yheader></Yheader>
    <router-view/>
    <Yfooter></Yfooter>
  </div>
</template>
<script>
// @ is an alias to /src
import Yheader from '@/components/header.vue'
import Yfooter from '@/components/footer.vue'
export default {
  name: 'Home',
  components: {
    Yheader,
    Yfooter
  },
  created() {
    localStorage.setItem('language',localStorage.getItem('language') || 'CN')
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "MohaveRegular"; /* 这个名字可以自己定义 */
  src: url("./fonts/MohaveRegular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,body{
  margin: 0;
  padding: 0;
}
img{
  display: block;
  max-width: 100%;
  height: auto;
}
video{
  display: block;
  max-width: 100%;
  height: auto;
}
@media screen and (min-width:1600px){ 
    body,html{ 
        font-size: 22px; 
    }
}
@media screen and (max-width:1600px) and (min-width:1400px){ 
    body,html{ 
        font-size: 18px; 
    }
}
@media screen and (max-width:1400px) and (min-width:1200px){ 
    body,html{ 
        font-size: 16px; 
    }
}
@media screen and (max-width:1200px) and (min-width:1000px){ 
    body,html{ 
        font-size: 14px; 
    }
}
@media screen and (max-width:1000px) and (min-width:750px){ 
    body,html{ 
        font-size: 12px; 
    }
}  
</style>
