<template>
	<div class="home">
		<div class="bannerBox" v-if="pcOrmb">
			<vue-particles class="particles" color="#dedede" :particleOpacity="0.7" :particlesNumber="100"
				shapeType="circle" :particleSize="3" linesColor="#dedede" :linesWidth="1" :lineLinked="true"
				:lineOpacity="0.4" :linesDistance="150" :moveSpeed="3" :hoverEffect="true" hoverMode="grab"
				:clickEffect="true" clickMode="push">
			</vue-particles>
			<div class="pcvideo">
				<video id="pcvideo" autoplay muted loop :controls="false" :x5-playsinline="true" :playsinline="true"
					:webkit-playsinline="true">
					<source src="video/pcvideo.MP4" type="video/mp4">

				</video>
			</div>
			<div class="box">
				<div class="word">
					<img class="img" src="@/assets/logo3.png" alt="">
					<div class="text">{{ $t('home.bannerText') }}</div>
				</div>
			</div>
		</div>
		<div class="bannerBoxMb" v-else>
			<vue-particles class="particles" color="#dedede" :particleOpacity="0.7" :particlesNumber="100"
				shapeType="circle" :particleSize="3" linesColor="#dedede" :linesWidth="1" :lineLinked="true"
				:lineOpacity="0.4" :linesDistance="150" :moveSpeed="3" :hoverEffect="true" hoverMode="grab"
				:clickEffect="true" clickMode="push">
			</vue-particles>
			<div class="pcvideo">
				<div id="pcvideoMb">

				</div>
			</div>
			<div class="box">
				<div class="word">
					<img class="img" src="@/assets/logo3.png" alt="">
					<div class="text">{{ $t('home.bannerText') }}</div>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="infoBox" v-if="indexData.desc">
				<div class="item">
					<img class="w1" src="@/assets/w1.png" alt="">
				</div>
				<div class="item">
					<img class="img" :src="baseHostUrl + indexData.desc[0].imgurl" alt="">
				</div>
				<div class="infoMain">
					<div class="list mar">

						<img data-aos="fade-up" data-aos-duration="500" class="logo" src="@/assets/erpin.svg" alt="">
						<div data-aos="fade-up" data-aos-duration="600" class="title" style="text-align: left;">
							{{ indexData.desc[0].title }}</div>
						<div data-aos="fade-up" data-aos-duration="700" class="infotext"
							v-html="indexData.desc[0].description"></div>
					</div>
					<div class="list" data-aos="fade-up"></div>
				</div>
			</div>
			<div class="dashiji">
				<div class="title">{{ $t('home.jishi') }}</div>
				<div class="itemBox" data-aos="fade-up" data-aos-duration="500">
					<div class="lines" v-if="indexData.event">
						<div class="item" v-for="(item, index) in indexData.event" :key="index">
							<div class="info" :class="index + 1 == indexData.event.length ? 'cc' : ''">
								<div class="textBox">
									<div class="text" v-html="item.content"></div>
									<div class="num">{{ item.happen_year }}</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="main">
				<!-- <div class="left" @click="slidePrev()">
          <img class="img" src="@/assets/icon_left@2x.png" alt="">
        </div> -->
				<div class="title">{{ $t('home.huoban') }}</div>
				<div class="mySwiper">
					<swiper ref="mySwiper" :options="swiperOptions">
						<swiper-slide>
							<div class="imgBoximgBox">
								<div class="imgBox" v-for="(item, index) in organdata" :key="index"
									:data-aos="index % 2 == 0 ? 'fade-up' : 'fade-down'" data-aos-duration="500">
									<img class="img" :src="baseHostUrl + item.imgurl" :alt="item.title">
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>

				<!-- <div class="right"  @click="slideNext()">
          <img class="img" src="@/assets/icon_rest@2x.png" alt="">
        </div> -->
			</div>
		</div>
		<div class="contentMb">
			<div class="infoBox" v-if="indexData.desc">
				<div class="item">
					<img class="w1" src="@/assets/w1.png" alt="">
				</div>
				<div class="item">
					<img class="img" :src="baseHostUrl + indexData.desc[0].imgurl" alt="">
				</div>
				<div class="infoMain">
					<div class="list mar">

						<img data-aos="fade-up" data-aos-duration="500" class="logo" src="@/assets/erpin.svg" alt="">
						<div data-aos="fade-up" data-aos-duration="600" class="title" style="text-align: left;">
							{{ indexData.desc[0].title }}</div>
						<div data-aos="fade-up" data-aos-duration="700" class="infotext"
							v-html="indexData.desc[0].description"></div>
					</div>
					<div class="list" data-aos="fade-up"></div>
				</div>
			</div>
			<div class="dashiji">
				<div class="title">{{ $t('home.jishi') }}</div>
				<div class="itemBox" data-aos="fade-up" data-aos-duration="500">
					<div class="lines" v-if="indexData.event">
						<div class="item" v-for="(item, index) in indexData.event" :key="index">
							<div class="info" :class="index + 1 == indexData.event.length ? 'cc' : ''">
								<div class="textBox">
									<div class="text" v-html="item.content"></div>
									<div class="num">{{ item.happen_year }}</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="mainMB">
				<div class="left" @click="slidePrevM()">
					<img class="img" src="@/assets/icon_left@2x.png" alt="">
				</div>
				<div class="title">{{ $t('home.huoban') }}</div>
				<div class="mySwiper">
					<swiper ref="mySwiperM" :options="swiperOptions">
						<swiper-slide v-for="(item, index) in organdataMB" :key="index">
							<div class="imgBox" v-for="(item, index) in item" :key="index"
								:data-aos="index % 2 == 0 ? 'fade-up' : 'fade-down'" data-aos-duration="500">
								<img class="img" :src="baseHostUrl + item.imgurl" :alt="item.title">
							</div>
						</swiper-slide>
					</swiper>
				</div>

				<div class="right" @click="slideNextM()">
					<img class="img" src="@/assets/icon_rest@2x.png" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { index, baseHostUrl, getorgandata } from '@/utils/index.js'
export default {
	name: 'Home',
	components: {

	},
	data() {
		return {
			swiperOptions: {
				// slidesPerView: 1,
				// spaceBetween: 30,
			},
			baseHostUrl: '',
			organdata: {},
			organdataMB: [],
			pcOrmb: true,
			indexData: {},
			baseHostUrl: ''
		}
	},
	computed: {
	},
	created() {
		if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
			this.pcOrmb = false
		} else {
			this.pcOrmb = true
		}
		if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
			this.swiperOptions.slidesPerView = 1
			this.swiperOptions.spaceBetween = 0
		} else {
			// this.swiperOptions.slidesPerView = 3
			// this.swiperOptions.spaceBetween = 30
		}
	},
	mounted() {
		this.index()
		this.baseHostUrl = baseHostUrl()
		this.getorgandata()
	},
	methods: {
		async index() {
			let that = this
			let data = {
				type: localStorage.getItem('language').toLowerCase()
			}
			let res = await index(data)
			that.indexData = res.data
		},
		async getorgandata() {
			let that = this
			let data = {
				type: localStorage.getItem('language').toLowerCase()
			}
			let res = await getorgandata(data)
			that.organdata = res.data
			that.organdataMB = that.group(that.organdata, 3)
		},
		group(array, subGroupLength) {
			let index = 0;
			let newArray = [];
			while (index < array.length) {
				newArray.push(array.slice(index, index += subGroupLength));
			}
			return newArray;
		},
		slideNext() {
			this.$refs.mySwiper.$swiper.slideNext()
		},
		slidePrev() {
			this.$refs.mySwiper.$swiper.slidePrev();
		},
		slideNextM() {
			this.$refs.mySwiperM.$swiper.slideNext()
		},
		slidePrevM() {
			this.$refs.mySwiperM.$swiper.slidePrev();
		}
	}
}
</script>
<style>
p {
	margin: 0;
}
</style>
<style lang="scss" scoped>
.bannerBox {
	height: 100%;
	position: relative;
	overflow: hidden;

	.particles {
		height: 100%;
	}

	.box {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 78vw;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;

		.word {
			.img {
				height: 4vw;
				margin: auto;
			}

			.text {
				font-size: 2vw;
				margin-top: 2vw;
				color: #fff;
			}
		}
	}

	.pcvideo {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: -1;

		#pcvideo {
			width: 100%;
			height: 100%;
			display: block;
		}
	}
}

.bannerBoxMb {
	display: none;
}

.content {
	.infoBox {
		display: flex;
		position: relative;

		.item {
			flex: 1;
			position: relative;

			.w1 {
				position: absolute;
				top: 0;
				left: 0;
				width: 20vw;
			}

			.img {
				width: 100%;
			}
		}

		.infoMain {
			position: absolute;
			width: 63vw;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			text-align: justify;

			.mar {
				margin-right: 5.1vw;
			}

			.list {
				flex: 0 0 50%;
				position: relative;

				.logo {
					height: 2.5vw;
				}

				.title {
					font-size: 1.6vw;
					font-weight: 700;
					color: #0B71B7;
					margin-top: 3.3vw;
					margin-right: 5.2vw;
				}

				.infotext {
					font-size: 0.88vw;
					margin-top: 1.83vw;
					line-height: 1.5vw;
					color: #787B87;
					margin-right: 5.2vw;
					height: 24vw;
					overflow-y: auto;
					padding: 0 15px 0 0;

					&::-webkit-scrollbar {
						width: 2px;
					}

					&::-webkit-scrollbar-track {
						border-radius: 10px;
					}

					&::-webkit-scrollbar-thumb {
						border-radius: 10px;
						background: #127CC0;
					}
				}
			}
		}
	}

	.dashiji {
		min-height: 34vw;
		background: url('../assets/pic3@2x.png') no-repeat;
		background-size: cover;
		overflow: hidden;
		position: relative;
		color: #787B87;

		.title {
			font-size: 2vw;
			color: #127CC0;
			font-weight: bold;
			text-align: center;
			margin-top: 7vw;
		}

		.itemBox {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;

			.lines {
				display: flex;
				overflow-x: scroll;
				overflow-y: hidden;

				&::-webkit-scrollbar {
					height: 6px;
				}

				&::-webkit-scrollbar-track {
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background: #127CC0;
				}

				.item {
					flex: 0 0 16%;
					vertical-align: top;
					position: relative;
					bottom: 0vw;
					display: flex;
					align-items: flex-end;

					&::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 2px;
						bottom: 0.3vw;
						left: 0;
						background-color: #127CC0;
						transform: rotate(-2deg);
					}

					&::before {
						content: '';
						position: absolute;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						bottom: 0.4vw;
						right: 0;
						background-color: #127CC0;

					}

					.info {
						position: relative;
						text-align: right;
						margin-bottom: 2vw;
						flex: 1;

						.textBox {
							width: 15vw;
							margin-right: -13vw;
							display: inline-block;
							text-align: left;
							margin-left: auto;
							font-size: 1vw;

							.time {
								margin-bottom: 0.3vw;
							}

							.text {

								margin-bottom: 0.9vw;
							}

							.num {
								font-size: 2vw;
								font-weight: bold;
								color: #127CC0;
								font-family: 'MohaveRegular';
							}
						}
					}

					&:nth-child(2) {
						&::after {
							content: '';
							bottom: 1vw;
							transform: rotate(-3deg);
						}

						&::before {
							content: '';
							bottom: 1.25vw;

						}

						.info {
							margin-bottom: 3vw;
						}
					}

					&:nth-child(3) {
						&::after {
							content: '';
							bottom: 2vw;
							transform: rotate(-4deg);
						}

						&::before {
							content: '';
							bottom: 2.4vw;

						}

						.info {
							margin-bottom: 4vw;
						}
					}

					&:nth-child(4) {
						&::after {
							content: '';
							bottom: 3.05vw;
							transform: rotate(-3deg);
						}

						&::before {
							content: '';
							bottom: 3.3vw;

						}

						.info {
							margin-bottom: 5vw;
						}
					}

					&:nth-child(5) {
						&::after {
							content: '';
							bottom: 4.2vw;
							transform: rotate(-5deg);
						}

						&::before {
							content: '';
							bottom: 4.7vw;

						}

						.info {
							margin-bottom: 6.5vw;
						}
					}

					&:nth-child(6) {
						&::after {
							content: '';
							bottom: 5.35vw;
							transform: rotate(-3deg);
						}

						&::before {
							content: '';
							bottom: 5.6vw;

						}

						.info {
							margin-bottom: 7.85vw;
						}
					}

					&:nth-child(7) {
						&::after {
							content: '';
							bottom: 6.2vw;
							transform: rotate(-3deg);
						}

						&::before {
							content: '';
							bottom: 6.4vw;

						}

						.info {
							margin-bottom: 8.1vw;
						}
					}

					&:nth-child(8) {
						&::after {
							content: '';
							bottom: 7vw;
							transform: rotate(-3deg);
						}

						&::before {
							content: '';
							bottom: 7.2vw;

						}

						.info {
							margin-bottom: 9.5vw;
						}
					}

					&:nth-child(9) {
						&::after {
							content: '';
							bottom: 8vw;
							transform: rotate(-4deg);
						}

						&::before {
							content: '';
							bottom: 8.3vw;

						}

						.info {
							margin-bottom: 10.5vw;
						}
					}

					&:nth-child(10) {
						&::after {
							content: '';
							bottom: 9vw;
							transform: rotate(-3deg);
						}

						&::before {
							content: '';
							bottom: 9.25vw;

						}

						.info {
							margin-bottom: 11.5vw;
						}
					}

					&:nth-child(11) {
						&::after {
							content: '';
							bottom: 9.7vw;
							transform: rotate(-2deg);
						}

						&::before {
							content: '';
							bottom: 9.8vw;

						}

						.info {
							margin-bottom: 12.1vw;
						}
					}

					// &:last-child{
					//   &::after{
					//     content: '';
					//     bottom: 191px;
					//     transform: rotate(-4deg);
					//   }
					// &::before{
					//   content: '';
					//     bottom: 175px;
					//     display: none;
					//   }
					//   .info{
					//     margin-bottom: 11.5vw;
					//   }
					// }
				}

				.cc {
					&::after {
						content: '';
						position: absolute;
						width: 100%;
						height: 2px;
						bottom: -1.8vw;
						right: -100%;
						background-color: #127CC0;
						transform: rotate(-2deg);
					}

					&::before {
						content: '';
						position: absolute;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						bottom: 9px;
						right: 0;
						background-color: #127CC0;
						display: none;
					}
				}
			}
		}
	}
}

.contentMb {
	display: none;
}

.main {
	background: url('../assets/bg@2x.png') no-repeat;
	height: 40vw;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.title {
		text-align: center;
		position: absolute;
		font-size: 2vw;
		color: #127CC0;
		font-weight: bold;
		top: 4vw;
	}

	.mySwiper {
		width: 63vw;
		overflow: hidden;

		.imgBoximgBox {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			flex: 1;
			margin-top: 3vw;
		}

		.imgBox {
			flex: 0 0 33.333%;
			margin-bottom: 3vw;

			.img {
				width: 14vw;
				margin: auto;
			}
		}
	}

	.left {
		background: #EDEEF0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1.5vw 0.8vw;
		border-radius: 0.2vw;
		cursor: pointer;
		margin-right: 2vw;

		.img {
			width: 1vw;
		}
	}

	.right {
		background: #EDEEF0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1.5vw 0.8vw;
		border-radius: 0.2vw;
		cursor: pointer;
		margin-left: 2vw;

		.img {
			width: 1vw;
		}
	}
}

.mainMB {
	display: none;
}

@media screen and (max-width:1366px) {
	.infotext {
		font-size: 1.1vw !important;
		line-height: 1.8vw !important;
	}
}

@media screen and (max-width:750px) {
	.bannerBox {
		display: none;
	}

	.bannerBoxMb {
		height: calc(100vh - 13.35vw);
		position: relative;
		overflow: hidden;
		display: block;

		.particles {
			height: 100vh;
		}

		.box {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 78vw;
			transform: translate(-50%, -50%);
			display: flex;
			align-items: center;
			justify-content: center;

			.word {
				.img {
					height: 12vw;
					margin: auto;
				}

				.text {
					font-size: 6.5vw;
					margin-top: 4vw;
					color: #fff;
					text-align: center;
				}
			}
		}

		.pcvideo {
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: -1;
			height: 100%;
			background: #006dbd;

			#pcvideo {
				width: auto;
				height: 100vh;
				display: block;
			}
		}
	}

	.content {
		display: none;
	}

	.contentMb {
		display: block;

		.infoBox {
			position: relative;

			.item {
				flex: 1;
				position: relative;

				.w1 {
					position: absolute;
					top: 0;
					left: 0;
					width: 60vw;
				}

				.img {
					width: 100%;
				}
			}

			.infoMain {
				display: flex;
				color: #333;
				padding: 8vw 5.5vw 0 5.5vw;
				text-align: justify;

				.list {
					position: relative;

					.logo {
						height: 7.5vw;
					}

					.title {
						font-size: 5vw;
						font-weight: 700;
						color: #0B71B7;
						margin: 4vw 0;
					}

					.infotext {
						font-size: 3.8vw !important;
						color: #787B86;
						margin-bottom: 10vw;
						line-height: 6vw !important;
					}
				}
			}
		}

		.dashiji {
			min-height: 110vw;
			background: url('../assets/pic3@2x.png') no-repeat;
			background-size: cover;
			overflow: hidden;
			position: relative;
			color: #787B87;

			.title {
				font-size: 7vw;
				color: #127CC0;
				font-weight: bold;
				text-align: center;
				margin-top: 10vw;
			}

			.itemBox {
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;

				.lines {
					display: flex;
					overflow-x: scroll;
					overflow-y: hidden;

					&::-webkit-scrollbar {
						height: 6px;
					}

					&::-webkit-scrollbar-track {
						border-radius: 10px;
					}

					&::-webkit-scrollbar-thumb {
						border-radius: 10px;
						background: #127CC0;
					}

					.item {
						flex: 0 0 33.33%;
						vertical-align: top;
						position: relative;
						bottom: 0vw;
						display: flex;
						align-items: flex-end;

						&::after {
							content: '';
							position: absolute;
							width: 100%;
							height: 2px;
							bottom: 4px;
							left: 0;
							background-color: #127CC0;
							transform: rotate(-6deg);
						}

						&::before {
							content: '';
							position: absolute;
							width: 8px;
							height: 8px;
							border-radius: 50%;
							bottom: 8px;
							right: 0;
							background-color: #127CC0;

						}

						.info {
							position: relative;
							text-align: right;
							margin-bottom: 8vw;
							flex: 1;

							.textBox {
								width: 26vw;
								margin-right: -20vw;
								display: inline-block;
								text-align: left;
								margin-left: auto;
								font-size: 3vw;

								.time {
									margin-bottom: 3vw;
								}

								.text {

									margin-bottom: 3vw;
								}

								.num {
									font-size: 6vw;
									font-weight: bold;
									color: #127CC0;
									font-family: 'MohaveRegular';
								}
							}
						}

						&:nth-child(2) {
							&::after {
								content: '';
								bottom: 15px;
								transform: rotate(-4deg);
							}

							&::before {
								content: '';
								bottom: 16px;

							}

							.info {
								margin-bottom: 11vw;
							}
						}

						&:nth-child(3) {
							&::after {
								content: '';
								bottom: 21px;
								transform: rotate(-2deg);
							}

							&::before {
								content: '';
								bottom: 20px;

							}

							.info {
								margin-bottom: 12vw;
							}
						}

						&:nth-child(4) {
							&::after {
								content: '';
								bottom: 28px;
								transform: rotate(-5deg);
							}

							&::before {
								content: '';
								bottom: 31px;

							}

							.info {
								margin-bottom: 14vw;
							}
						}

						&:nth-child(5) {
							&::after {
								content: '';
								bottom: 36px;
								transform: rotate(-2deg);
							}

							&::before {
								content: '';
								bottom: 35px;

							}

							.info {
								margin-bottom: 16vw;
							}
						}

						&:nth-child(6) {
							&::after {
								content: '';
								bottom: 42px;
								transform: rotate(-4deg);
							}

							&::before {
								content: '';
								bottom: 44px;

							}

							.info {
								margin-bottom: 18vw;
							}
						}

						&:nth-child(7) {
							&::after {
								content: '';
								bottom: 49px;
								transform: rotate(-2deg);
							}

							&::before {
								content: '';
								bottom: 49px;

							}

							.info {
								margin-bottom: 20vw;
							}
						}

						&:nth-child(8) {
							&::after {
								content: '';
								bottom: 56px;
								transform: rotate(-4deg);
							}

							&::before {
								content: '';
								bottom: 58px;

							}

							.info {
								margin-bottom: 22vw;
							}
						}

						&:nth-child(9) {
							&::after {
								content: '';
								bottom: 63px;
								transform: rotate(-2deg);
							}

							&::before {
								content: '';
								bottom: 63px;

							}

							.info {
								margin-bottom: 25vw;
							}
						}

						&:nth-child(10) {
							&::after {
								content: '';
								bottom: 74px;
								transform: rotate(-7deg);
							}

							&::before {
								content: '';
								bottom: 79px;

							}

							.info {
								margin-bottom: 28vw;
							}
						}
						&:nth-child(11) {
							&::after {
								content: '';
								bottom: 86px;
								transform: rotate(-4deg);
							}

							&::before {
								content: '';
								bottom: 87px;

							}

							.info {
								margin-bottom: 120px;
							}
						}

					}

					.cc {
						&::after {
							content: '';
							position: absolute;
							width: 100%;
							height: 2px;
							bottom: -6vw;
							right: -100%;
							background-color: #127CC0;
							transform: rotate(-6deg);
						}

						&::before {
							content: '';
							position: absolute;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							bottom: 10px;
							right: 0;
							background-color: #127CC0;
							display: none;
						}
					}
				}
			}
		}

	}

	.mainMB {
		background: url('../assets/bg@2xmb.png') no-repeat;
		height: 106vw;
		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		.title {
			text-align: center;
			position: absolute;
			font-size: 7vw;
			color: #127CC0;
			font-weight: bold;
			top: 10vw;
		}

		.mySwiper {
			width: 100%;
			overflow: hidden;
			margin-top: 20vw;

			.imgBox {
				.img {
					width: 45vw;
					margin: auto auto 11vw auto;
				}

				&:last-child {
					.img {
						width: 45vw;
						margin: auto auto 0 auto;
					}
				}
			}
		}

		.left {
			background: #EDEEF0;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 3vw 4vw;
			border-radius: 0.2vw;
			cursor: pointer;
			margin-right: 2vw;

			.img {
				width: 3vw;
			}
		}

		.right {
			background: #EDEEF0;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 3vw 4vw;
			border-radius: 0.2vw;
			cursor: pointer;
			margin-left: 2vw;

			.img {
				width: 3vw;
			}
		}
	}
}</style>
