import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import AOS from "aos"
import "../node_modules/aos/dist/aos.css"
AOS.init();

import VueParticles from 'vue-particles'
Vue.use(VueParticles)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'CN',
  messages: {
    'CN': require('@/lib/cn.json'),
    'EN': require('@/lib/en.json')
  }
});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
