<template>
  <div class="Yheader">
    <div class="box">
      <div class="logo" @click="onIndex()">
        <img  class="img" src="@/assets/daohang.svg" alt="">
      </div>
      <div class="itemBox">
        <div class="item" :class="navIndex == item.path?'active':''" v-for="(item,index) in  $i18n.locale == 'CN'?navList:navList2" :key="index" @click="onNav(item.path)">{{$t(item.name)}}</div>
        <div class="langBox" >
         
          <img class="language" src="@/assets/language.png" alt="">
          <div class="text">{{$i18n.locale}}</div>
        
          <div class="xuanzhe" >
            <div class="i" @click="onCkLang('CN')">{{$t('nav.Chinese')}}</div>
            <div class="i" @click="onCkLang('EN')">{{$t('nav.English')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mbBox">
      <div class="logo" @click="onIndex()">
        <img  class="img" src="@/assets/daohang.svg" alt="">
      </div>
      <div class="mo" @click="showListMb()">
        <img  class="img" src="@/assets/mb.png" alt="">
      </div>
    </div>
    <div class="mbList" :style="{right:mbShowList?'0':'-100%'}">
      <div class="title">
        <img  class="img" src="@/assets/xx.png" alt="" @click="showListMb()">
      </div>
      <div class="listBox">
        <div class="list" :class="navIndex == item.path?'active':''" v-for="(item,index) in $i18n.locale == 'CN'?navList:navList2" :key="index" @click="onNav(item.path)">{{$t(item.name)}}</div>
      </div>
      <div class="langBox" @click.stop="onshowLang()" >
         
        <img class="language" src="@/assets/languagef.png" alt="">
        <div class="text">{{$i18n.locale}}</div>
      
        <div class="xuanzhe" v-if="showLang">
          <div class="i" @click="onCkLang('CN')">{{$t('nav.Chinese')}}</div>
          <div class="i" @click="onCkLang('EN')">{{$t('nav.English')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Yheader',
  data () {
    return {
      navIndex: '/',
      showLang: false,
      navList: [
        {
          name: 'nav.about',
          path: '/'
        },
        {
          name: 'nav.productInformation',
          path: '/productInformation'
        },
        {
          name: 'nav.dynamic',
          path: '/dynamic'
        },
        {
          name: 'nav.join',
          path: '/join'
        }
      ],
      navList2: [
        {
          name: 'nav.about',
          path: '/'
        },
        {
          name: 'nav.productInformation',
          path: '/productInformation'
        },
        {
          name: 'nav.join',
          path: '/join'
        }
      ],
      mbShowList: false
    }
  },
  watch: {
    $route(to, from) {   
      this.navIndex = to.path  
    }
  },
  methods: {
    onNav(path) {
      this.mbShowList = false
      this.$router.push(path)
    },
    onIndex() {
      this.$router.push('/')
    },
    onshowLang() {
      this.showLang = !this.showLang
    },
    onCkLang(val) {
      localStorage.setItem('language',val)
      this.$i18n.locale = localStorage.getItem('language')
      window.location.reload()
    },
    showListMb() {
      this.mbShowList = !this.mbShowList
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.Yheader{
  padding: 0 3vw;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 9;
  .box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.9vw;
    .logo{
      cursor: pointer;
      .img{
        height: 1.7vw;
      }
    }
    .itemBox{
      display: flex;
      align-items: center;
      .item{
        color: #263247;
        margin: 0 3.9vw;
        font-size: 0.9vw;
        font-weight:400;
        cursor: pointer;
        line-height: 3.8vw;
        border-bottom: solid 2px #fff;
      }
      
      .active{
        color: #0B71B7;
        border-bottom: solid 2px #0B71B7;

      }
      .langBox{
        display: flex;
        align-items: center;
        color: #263247;
        font-size: 0.88vw;
        font-weight:400;
        cursor: pointer;
        margin-left: 1.7vw;
        position: relative;
        line-height: 3.8vw;
        &:hover{
          .xuanzhe{
            display: block !important;
          }
        }
        .ss{
          display: flex;
          align-items: center;
        }
        .language{
          width: 0.9vw;
          height: 0.9vw;
          margin-right: 0.34vw
        }
        .xuanzhe{
          position: absolute;
          display: none;
          width: 6vw;
          background: #fff;
          left: 50%;
          transform: translate(-50%,0);
          top: 3vw;
          text-align: center;
          box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
          padding: 0.6vw 0;
          z-index: 999;
          line-height: 1.6;
          .i{
            font-size: 0.88vw;
            padding: 0.6vw 0;
            &:hover{
              color: #0B71B7;
            }
          }
        }
      }
    }
  }
  .mbBox{
    display: none;
  }
  .mbList{
    display: none;
  }
  @media screen and (max-width:1366px){ 
    .itemBox{
      .item{
          font-size: 1.1vw !important;
      }
    }
  }
  @media screen and (max-width:750px){ 
    .box{ 
      display: none;
    }
    .mbBox{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 13.35vw;
      padding: 0 2vw;
      .logo{
        .img{
          height: 6vw;
        }
      }
      .mo{
        .img{
          height: 4.5vw;
        }
      }
    }
    .mbList{
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      right: -100%;
      background: #1271B7;
      z-index: 10;
      transition: all 0.3s ease-out;
      display: block;
      &::after{
        content: '';
        position: absolute;
        background: url('../assets/pic_bg.png') no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        bottom: 0;
        z-index: -1;
      }
      .title{
        display: flex;
        align-items: center;
        height: 13.35vw;
        padding: 0 4vw;
        border-bottom: solid 1px rgba($color: #E5E5E5, $alpha: 0.32);
        .img{
          height: 6vw;
          margin-left: auto;
        }
      }
      .listBox{
        .list{
          text-align: center;
          color: rgba($color: #fff, $alpha: 0.7);
          font-size: 4.3vw;
          margin-top: 11vw;
        }
        .active{
          color: #fff;
          font-weight: 700;
        }
      }
    }
    .langBox{
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 3.5vw;
      font-weight:400;
      cursor: pointer;
      margin-left: 1.7vw;
      position: absolute;
      bottom: 14vw;
      right: 10vw;
      z-index: 1;
      .ss{
        display: flex;
        align-items: center;
      }
      .language{
        width: 4vw;
        height: 4vw;
        margin-right: 1.5vw
      }
      .xuanzhe{
        position: absolute;
        width: 20vw;
        background: #fff;
        left: 50%;
        transform: translate(-50%,0);
        bottom: 6vw;
        text-align: center;
        box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
        padding: 0.6vw 0;
        z-index: 999;
        .i{
          font-size: 2vw;
          padding: 3vw 0;
          color: #333;
          &:hover{
            color: #0B71B7;
          }
        }
      }
    }
  } 
}

</style>
