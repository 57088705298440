<template>
	<div class="Yfooter">
		<div class="YfooterPc">
			<div class="content">
				<div class="left">
					<div class="logo">
						<img class="img" src="@/assets/yejia.svg" alt="">
					</div>
					<div class="itemBox">
						<div class="item" v-html="footerData.hotline"></div>
						<div class="item" v-html="footerData.email"></div>
						<div class="item" v-html="footerData.shaddress"></div>
						<div class="item" v-html="footerData.shaddress1"></div>
						<div class="item" v-html="footerData.bjaddress"></div>
						<div class="item" v-html="footerData.njaddress"></div>
						<div class="item" v-html="footerData.njaddress1"></div>
						<div class="item" v-html="footerData.cdaddress"></div>
					</div>
				</div>
				<div class="right">
					<img class="qr" src="@/assets/QR.png" alt="">
					<div class="text">{{ $t('footer.codeText') }}</div>
				</div>
			</div>
			<div class="company">
				<div class="copyright" @click="onCopyrigh('https://beian.miit.gov.cn/#/Integrated/index')">
					{{ footerData.copyright }} - {{ footerData.beian }}</div>
				<div class="itemBox">
					<div class="item" v-for="(item, index) in $i18n.locale == 'CN' ? navList : navList2" :key="index"
						@click="onNav(item.path)">{{ $t(item.name) }}</div>
					<div class="langBox" @click.stop="onshowLang()">
						<img class="language" src="@/assets/languagef.png" alt="">
						<div class="text">{{ $i18n.locale }}</div>
						<div class="xuanzhe" v-if="showLang">
							<div class="i" @click="onCkLang('CN')">{{ $t('nav.Chinese') }}</div>
							<div class="i" @click="onCkLang('EN')">{{ $t('nav.English') }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="YfooterMb">
			<div class="codeBox">
				<img class="qr" src="@/assets/QR.png" alt="">
				<div class="text">{{ $t('footer.codeText') }}</div>
			</div>
			<div class="infoBox">
				<div class="logo">
					<img class="img" src="@/assets/yejia.svg" alt="">
				</div>
				<div class="itemBox">
					<div class="item" v-html="footerData.hotline"></div>
					<div class="item" v-html="footerData.email"></div>
					<div class="item" v-html="footerData.shaddress"></div>
					<div class="item" v-html="footerData.shaddress1"></div>
					<div class="item" v-html="footerData.bjaddress"></div>
					<div class="item" v-html="footerData.njaddress"></div>
					<div class="item" v-html="footerData.njaddress1"></div>
					<div class="item" v-html="footerData.cdaddress"></div>
				</div>
			</div>
			<div class="company">

				<div class="itemBox">
					<div class="item" v-for="(item, index) in $i18n.locale == 'CN' ? navList : navList2" :key="index"
						@click="onNav(item.path)">{{ $t(item.name) }}</div>
					<div class="langBox">
						<img class="language" src="@/assets/languagef.png" alt="">
						<div class="text">{{ $i18n.locale }}</div>
						<div class="xuanzhe">
							<div class="i" @click="onCkLang('CN')">{{ $t('nav.Chinese') }}</div>
							<div class="i" @click="onCkLang('EN')">{{ $t('nav.English') }}</div>
						</div>
					</div>
				</div>
				<div class="copyright" @click="onCopyrigh('https://beian.miit.gov.cn/#/Integrated/index')">
					{{ footerData.copyright }} - {{ footerData.beian }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getheadfootdata } from '@/utils/index.js'
export default {
	name: 'Yfooter',
	data() {
		return {
			Lang: 'CN',
			navIndex: 0,
			navList: [
				{
					name: 'nav.about',
					path: '/'
				},
				{
					name: 'nav.productInformation',
					path: '/productInformation'
				},
				{
					name: 'nav.dynamic',
					path: '/dynamic'
				},
				{
					name: 'nav.join',
					path: '/join'
				}
			],
			navList2: [
				{
					name: 'nav.about',
					path: '/'
				},

				{
					name: 'nav.join',
					path: '/join'
				}
			],
			footerData: {},
			showLang: false
		}
	},
	created() {
		this.getheadfootdata()
	},
	methods: {
		async getheadfootdata() {
			let that = this
			let data = {
				type: localStorage.getItem('language').toLowerCase()
			}
			let res = await getheadfootdata(data)
			that.footerData = res.data.foot
			document.querySelector('meta[name="keywords"]').setAttribute('content', res.data.head.keywords);
			document.querySelector('meta[name="description"]').setAttribute('content', res.data.head.description);
		},
		onshowLang() {
			this.showLang = !this.showLang
		},
		onNav(path) {
			this.$router.push(path)
		},
		onCkLang(val) {
			localStorage.setItem('language', val)
			this.$i18n.locale = localStorage.getItem('language')
			window.location.reload()
		},
		onCopyrigh(url) {
			window.open(url, '_banlk')
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.Yfooter {
	padding: 0 3vw;
	background: #1472B7;

	.content {
		width: 63vw;
		margin: auto;
		display: flex;
		padding-top: 3.9vw;
		align-items: flex-end;

		.left {
			flex: 1;

			.logo {
				.img {
					height: 1.72vw;
				}
			}

			.itemBox {
				margin-top: 2.94vw;

				.item {
					font-size: 0.8vw;
					color: rgba($color: #fff, $alpha: 0.8);
					line-height: 1.8vw;
				}
			}
		}

		.right {
			.qr {
				width: 7.1vw;
				margin: auto auto 0.55vw auto;
			}

			.text {
				font-size: 0.7vw;
				color: #fff;
				line-height: 1.8vw;
				text-align: center;
			}
		}
	}

	.company {
		width: 63vw;
		margin: 3vw auto auto auto;
		display: flex;
		align-items: center;
		border-top: solid 1px rgba($color: #F6F7F9, $alpha: 0.15);
		height: 4vw;

		.copyright {
			font-size: 0.7vw;
			color: rgba($color: #fff, $alpha: 0.8);
			cursor: pointer;
			flex: 1;
		}

		.itemBox {
			display: flex;
			align-items: center;
			margin-left: auto;

			.item {
				color: rgba($color: #fff, $alpha: 0.8);
				margin: 0 1.66vw;
				font-size: 0.7vw;
				font-weight: 400;
				cursor: pointer;
				line-height: 4vw;
			}

			.langBox {
				display: flex;
				align-items: center;
				color: rgba($color: #fff, $alpha: 0.8);
				font-size: 0.82vw;
				font-weight: 400;
				cursor: pointer;
				margin-left: 1.66vw;
				position: relative;
				line-height: 4vw;

				&:hover {
					.xuanzhe {
						display: block !important;
					}
				}

				.language {
					width: 0.88vw;
					margin-right: 0.4vw;
				}

				.xuanzhe {
					position: absolute;
					width: 6vw;
					display: none;
					background: #fff;
					left: 50%;
					transform: translate(-50%, 0);
					bottom: 3vw;
					text-align: center;
					box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
					padding: 0.6vw 0;
					z-index: 999;
					line-height: 1.6;

					.i {
						font-size: 0.88vw;
						padding: 0.6vw 0;
						color: #333;

						&:hover {
							color: #0B71B7;
						}
					}
				}
			}
		}
	}

	.YfooterMb {
		display: none;
	}

	@media screen and (max-width:1366px) {
		.left {
			.logo {
				.img {
					height: 2.8vw !important;
				}
			}

			.itemBox {
				.item {
					font-size: 1vw !important;
					line-height: 2vw !important;
				}
			}
		}

		.right {
			.text {
				font-size: 1vw !important;
			}
		}
	}

	@media screen and (max-width:750px) {
		.YfooterPc {
			display: none;
		}

		.YfooterMb {
			display: block;
			padding-top: 18vw;
			padding: 18vw 0 0 0;

			.codeBox {
				.qr {
					width: 24vw;
					margin: auto auto 4vw auto;
				}

				.text {
					font-size: 3vw;
					color: #fff;
					text-align: center;
				}
			}

			.infoBox {
				padding-top: 14vw;

				.logo {
					.img {
						height: 7vw;
					}
				}

				.itemBox {
					margin-top: 4vw;

					.item {
						font-size: 3.8vw;
						color: rgba($color: #fff, $alpha: 0.8);
						margin-bottom: 1vw;
						line-height: 6vw;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			.company {
				border-top: solid 1px rgba($color: #F6F7F9, $alpha: 0.15);
				margin: 5vw 0 0 0;
				display: block;
				width: 100%;
				padding-bottom: 5vw;
				height: auto;

				.copyright {
					color: rgba($color: #fff, $alpha: 0.8);
					text-align: center;
					cursor: pointer;
					font-size: 2.5vw;

					div {
						font-size: 1vw;
					}
				}

				.itemBox {
					display: flex;
					align-items: center;
					margin-left: auto;
					height: 10vw;
					padding: 0 2.5vw 0 2.5vw;

					.item {
						color: rgba($color: #fff, $alpha: 0.8);
						margin: 0 1.66vw;
						font-size: 3vw;
						font-weight: 400;
						cursor: pointer;
						flex: 1;
					}

					.langBox {
						display: flex;
						align-items: center;
						color: rgba($color: #fff, $alpha: 0.8);
						font-size: 3vw;
						font-weight: 400;
						cursor: pointer;

						.language {
							width: 3.5vw;
							margin-right: 2vw;
						}

						.xuanzhe {
							position: absolute;
							width: 14vw;
							background: #fff;
							left: 50%;
							transform: translate(-50%, 0);
							bottom: 6vw;
							text-align: center;
							box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
							padding: 0.6vw 0;
							z-index: 999;

							.i {
								font-size: 2vw;
								padding: 3vw 0;
								color: #333;

								&:hover {
									color: #0B71B7;
								}
							}
						}
					}
				}
			}
		}

	}
}</style>
